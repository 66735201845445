import moment from 'moment-timezone'
import {
  buildDefaultFilters,
  calculatePercent,
  summaryFunctions,
  secondsToHours,
  formatActiveColumn,
  calculateRate,
} from 'utils/reporting'
import { YES_NO_OPTIONS } from 'registrationReports/constants'
import { baseFieldColumns } from 'registrationReports/reportsConfig/columns/baseFieldColumns'
import { findOtherColTotals } from 'registrationReports/reportsConfig/columnTotals'

export default {
  id: 'canvasser_quality_by_group',
  name: 'Field',
  permissionId: 'cover_sheet',
  description:
    'This report compares total forms from the cover sheet to total forms from the scans and counts from the visual QC process. The data is broken out by both raw totals and percentages.',
  dataDictionaryUrl:
    'https://docs.google.com/spreadsheets/d/1icVWct65EhNnIXgnfSt_VslP8iwrZ6Rz8SztYzuNxw8/edit#gid=2049147248',
  columns: [
    {
      dataKey: 'turf_name',
      title: 'Turf Name',
      type: 'string',
      resizable: true,
    },
    {
      dataKey: 'active_turf',
      title: 'Active Turf',
      type: 'enum',
      options: YES_NO_OPTIONS,
      resizable: true,
      deriveFunction: ({ rowData }) =>
        formatActiveColumn(rowData.turf_archived),
    },
    {
      dataKey: 'max_shift_start',
      title: 'Last Canvass Date',
      type: 'date',
      bodyCell: ({ cellData }) => {
        if (cellData === '') return cellData
        return moment(cellData).format('YYYY-MM-DD')
      },
      csvCell: ({ max_shift_start }) => {
        if (max_shift_start === '') return max_shift_start
        return moment(max_shift_start).format('YYYY-MM-DD')
      },
      resizable: true,
    },
    ...baseFieldColumns,
    {
      dataKey: 'percent_on_delivered_packets_cover',
      title: 'Percent on Delivered Packets (Cover)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculatePercent(
          rowData.scans_on_delivered_packet_cover,
          rowData.total_scans_cover
        ),
      summaryFunction: ({ data, acc }) =>
        summaryFunctions.customPercent(data, acc, [
          'scans_on_delivered_packet_cover',
          'total_scans_cover',
        ]),
    },
    {
      dataKey: 'flags_ready',
      title: 'Flags Ready for Group QC',
      type: 'number',
      resizable: true,
      summaryFunction: summaryFunctions.sum,
    },
    {
      dataKey: 'avg_time_to_resolve_canvasser_issues',
      title: 'Average Flag Resolution Time - Canvasser Issues (hours)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        secondsToHours(rowData.avg_time_to_resolve_canvasser_issues),
      summaryFunction: summaryFunctions.avg,
    },
    {
      dataKey: 'avg_time_to_resolve_other_issues',
      title: 'Average Flag Resolution Time - Other Issues (hours)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        secondsToHours(rowData.avg_time_to_resolve_other_issues),
      summaryFunction: summaryFunctions.avg,
    },
    {
      dataKey: 'avg_upload_time',
      title: 'Average Upload Time (hours)',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) => secondsToHours(rowData.avg_upload_time),
      summaryFunction: summaryFunctions.avg,
    },
    {
      dataKey: 'canvasser_flags_per_hundred',
      title: 'Canvasser flags per 100 forms',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculateRate(rowData.total_canvasser_flags, rowData.total_scans / 100),
      summaryFunction: ({ data, acc }) => {
        const [val1, val2] = findOtherColTotals(data, acc, [
          'total_canvasser_flags',
          'total_scans',
        ])
        return calculateRate(val1, val2 / 100)
      },
    },
    {
      dataKey: 'technical_flags_per_hundred',
      title: 'Technical flags per 100 forms',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        calculateRate(rowData.total_technical_flags, rowData.total_scans / 100),
      summaryFunction: ({ data, acc }) => {
        const [val1, val2] = findOtherColTotals(data, acc, [
          'total_technical_flags',
          'total_scans',
        ])
        return calculateRate(val1, val2 / 100)
      },
    },
    {
      dataKey: 'total_flags',
      title: 'Total Flags',
      type: 'number',
      resizable: true,
      deriveFunction: ({ rowData }) =>
        rowData.total_canvasser_flags + rowData.total_technical_flags,
      summaryFunction: summaryFunctions.sum,
    },
  ],
  get defaultFilters() {
    return buildDefaultFilters(this.columns, this.id, [
      {
        title: 'Canvass Information',
        visibleColumns: [
          'turf_name',
          'active_turf',
          'total_scans_cover',
          'scans_per_field_hour_cover',
          'total_scans_qc',
          'complete_scan_percent_qc',
          'percent_with_phones_qc',
        ],
      },
      {
        title: 'Raw Counts',
        visibleColumns: [
          'turf_name',
          'active_turf',
          'total_scans',
          'total_scans_qc',
          'complete_scans_cover',
          'complete_scans_qc',
          'incomplete_scans_cover',
          'incomplete_scans_qc',
          'scans_with_phones_cover',
          'scans_with_phones_qc',
        ],
      },
      {
        title: 'Percentages',
        visibleColumns: [
          'turf_name',
          'active_turf',
          'total_scans',
          'total_scans_qc',
          'complete_scan_percent_cover',
          'complete_scan_percent_qc',
          'incomplete_scan_percent_cover',
          'incomplete_scan_percent_qc',
          'percent_with_phones_cover',
          'percent_with_phones_qc',
        ],
      },
      {
        title: 'QC Check In',
        visibleColumns: [
          'turf_name',
          'active_turf',
          'total_scans_cover',
          'total_scans_qc',
          'complete_scan_percent_qc',
          'percent_with_phones_qc',
          'avg_time_to_resolve_canvasser_issues',
          'avg_time_to_resolve_other_issues',
          'avg_upload_time',
        ],
      },
    ])
  },
}
